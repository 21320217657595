import * as React from 'react';
import { formatMoneyAmount } from 'app/utils/format/currencyFormatter';
import { Link } from 'react-router-dom';
import { formatISOZonedDateTimeToUTCDateTime } from 'app/utils/format/dateTimeFormatter';

type Props = {
  charges: Object[],
  loading: boolean,
  action?: Function,
};

const FailedChargesTable = ({ charges, loading, action }: Props) => {
  const withChargeRequest =
    Component =>
    ({ charge }) =>
      <Component charge={charge} />;
  const Button = action && withChargeRequest(action);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <i className="fa fa-spinner fa-spin h1" />
      </div>
    );
  }

  if (!charges || !charges.length) {
    return (
      <div className="d-flex justify-content-center">
        <div>No failed charges found.</div>
      </div>
    );
  }

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>User Id</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Status message</th>
            <th>Created At</th>
            <th>Attempted At</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {charges.map(charge => (
            <tr key={charge.chargeId}>
              <td>
                <Link to={`/users/user/${charge.userId}/paymentdetails`}>{charge.userId}</Link>
              </td>
              <td>{formatMoneyAmount(charge.amount)}</td>
              <td>{charge.status}</td>
              <td>{charge.statusMessage}</td>
              <td>{formatISOZonedDateTimeToUTCDateTime(charge.createdAt)}</td>
              <td>{formatISOZonedDateTimeToUTCDateTime(charge.attemptedAt)}</td>
              {Button && (
                <td align="center">
                  <Button charge={charge} />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default FailedChargesTable;
