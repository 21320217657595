export const createEligibilityRulesFormDetail = rules => {
  const retrievedEligibilityRulesOptions = {};

  if (rules.includeFilter) {
    if (rules.includeFilter.workLocations) {
      retrievedEligibilityRulesOptions.workLocations = rules.includeFilter.workLocations
        .map(value => (value === null ? 'EMPTY' : value))
        .join(',');
      retrievedEligibilityRulesOptions.workLocationCriteria = 'include';
    }
    if (rules.includeFilter.legalEntities) {
      retrievedEligibilityRulesOptions.legalEntities = rules.includeFilter.legalEntities
        .map(value => (value === null ? 'EMPTY' : value))
        .join(',');
      retrievedEligibilityRulesOptions.legalEntityCriteria = 'include';
    }
    if (rules.includeFilter.employmentTypes) {
      retrievedEligibilityRulesOptions.employmentTypes = rules.includeFilter.employmentTypes
        .map(value => (value === null ? 'EMPTY' : value))
        .join(',');
      retrievedEligibilityRulesOptions.employmentTypeCriteria = 'include';
    }

    if (rules.includeFilter.groups) {
      retrievedEligibilityRulesOptions.groups = rules.includeFilter.groups.join(',');
      retrievedEligibilityRulesOptions.groupsCriteria = 'include';
    }
  }
  if (rules.excludeFilter) {
    if (rules.excludeFilter.workLocations) {
      retrievedEligibilityRulesOptions.workLocations = rules.excludeFilter.workLocations
        .map(value => (value === null ? 'EMPTY' : value))
        .join(',');
      retrievedEligibilityRulesOptions.workLocationCriteria = 'exclude';
    }

    if (rules.excludeFilter.legalEntities) {
      retrievedEligibilityRulesOptions.legalEntities = rules.excludeFilter.legalEntities
        .map(value => (value === null ? 'EMPTY' : value))
        .join(',');
      retrievedEligibilityRulesOptions.legalEntityCriteria = 'exclude';
    }

    if (rules.excludeFilter.employmentTypes) {
      retrievedEligibilityRulesOptions.employmentTypes = rules.excludeFilter.employmentTypes
        .map(value => (value === null ? 'EMPTY' : value))
        .join(',');
      retrievedEligibilityRulesOptions.employmentTypeCriteria = 'exclude';
    }

    if (rules.excludeFilter.groups) {
      retrievedEligibilityRulesOptions.groups = rules.excludeFilter.groups.join(',');
      retrievedEligibilityRulesOptions.groupsCriteria = 'exclude';
    }
  }

  return retrievedEligibilityRulesOptions;
};

export const createEligibilityRulesRequestBodyDetail = eligibilityRulesDetail => {
  const eligibilityRulesBody = {
    includeFilter: { legalEntities: [], workLocations: [], employmentTypes: [], groups: [] },
    excludeFilter: { legalEntities: [], workLocations: [], employmentTypes: [], groups: [] },
  };

  if (eligibilityRulesDetail.workLocations) {
    if (eligibilityRulesDetail.workLocationCriteria === 'include') {
      eligibilityRulesBody.includeFilter.workLocations = eligibilityRulesDetail.workLocations
        .split(',')
        .map(item => (item.trim() === 'EMPTY' ? null : item.trim()))
        .filter(item => item !== '');
    } else if (eligibilityRulesDetail.workLocationCriteria === 'exclude') {
      eligibilityRulesBody.excludeFilter.workLocations = eligibilityRulesDetail.workLocations
        .split(',')
        .map(item => (item.trim() === 'EMPTY' ? null : item.trim()))
        .filter(item => item !== '');
    }
  }
  if (eligibilityRulesDetail.legalEntities) {
    if (eligibilityRulesDetail.legalEntityCriteria === 'include') {
      eligibilityRulesBody.includeFilter.legalEntities = eligibilityRulesDetail.legalEntities
        .split(',')
        .map(item => (item.trim() === 'EMPTY' ? null : item.trim()))
        .filter(item => item !== '');
    } else if (eligibilityRulesDetail.legalEntityCriteria === 'exclude') {
      eligibilityRulesBody.excludeFilter.legalEntities = eligibilityRulesDetail.legalEntities
        .split(',')
        .map(item => (item.trim() === 'EMPTY' ? null : item.trim()))
        .filter(item => item !== '');
    }
  }
  if (eligibilityRulesDetail.employmentTypes) {
    if (eligibilityRulesDetail.employmentTypeCriteria === 'include') {
      eligibilityRulesBody.includeFilter.employmentTypes = eligibilityRulesDetail.employmentTypes
        .split(',')
        .map(item => (item.trim() === 'EMPTY' ? null : item.trim()))
        .filter(item => item !== '');
    } else if (eligibilityRulesDetail.employmentTypeCriteria === 'exclude') {
      eligibilityRulesBody.excludeFilter.employmentTypes = eligibilityRulesDetail.employmentTypes
        .split(',')
        .map(item => (item.trim() === 'EMPTY' ? null : item.trim()))
        .filter(item => item !== '');
    }
  }

  if (eligibilityRulesDetail.groups) {
    if (eligibilityRulesDetail.groupsCriteria === 'include') {
      eligibilityRulesBody.includeFilter.groups = eligibilityRulesDetail.groups
        .split(',')
        .map(item => item.trim())
        .filter(item => item !== '');
    } else if (eligibilityRulesDetail.groupsCriteria === 'exclude') {
      eligibilityRulesBody.excludeFilter.groups = eligibilityRulesDetail.groups
        .split(',')
        .map(item => item.trim())
        .filter(item => item !== '');
    }
  }

  return eligibilityRulesBody;
};
