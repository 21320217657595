import { useState } from 'react';

type Props = {
  chargeStats: Object,
};

const transactionStatuses = {
  NEW: {
    label: 'New',
    level: 'info',
    icon: 'fa fa-clock',
    description: `Transactions that we haven't initiated yet.`,
  },
  PROCESSING: {
    label: 'In Progress',
    level: 'info',
    icon: 'fa fa-spinner fa-spin',
    description:
      'Transactions that are currently being processed and have not yet reached a final state',
  },
  SUCCEEDED: {
    label: 'Successful',
    level: 'success',
    icon: 'fa fa-check',
    description: 'Transactions that were completed successfully and funds were processed',
  },
  ERROR: {
    label: 'Error',
    level: 'danger',
    icon: 'fa fa-times',
    description:
      'Transactions that encountered unexpected errors preventing processing (eg. missing payment method or technical issues)',
  },
  FAILED: {
    label: 'Failed',
    level: 'danger',
    icon: 'fa fa-exclamation-triangle',
    description:
      'Transactions that were attempted but could not be completed successfully due to errors or other issues on the adyen/customer side',
  },
  FAILED_RETRIES_EXHAUSTED: {
    label: 'Failed Retries Exhausted',
    level: 'danger',
    icon: 'fa fa-exclamation-triangle',
    description: 'Transactions that have failed and have exhausted all retry attempts',
  },
  CHARGED_BACK: {
    label: 'Charged Back',
    level: 'warning',
    icon: 'fa fa-undo',
    description: 'Transactions that were charged back by the customer/bank',
  },
};

const TransactionProgressBar = ({ chargeStats }: Props) => {
  const [legendExpanded, setLegendExpanded] = useState(false);

  const processedCharges = Object.entries(chargeStats.statusCounts)
    .filter(([key]) => key !== 'NEW')
    .reduce((acc, [key, value]) => acc + value, 0);
  const ratio = processedCharges / chargeStats.total.count;
  const progress =
    ratio > 1
      ? Math.round((processedCharges / chargeStats.total.count) * 100)
      : ((processedCharges / chargeStats.total.count) * 100).toFixed(2);

  const orderedStatusCounts = Object.entries(chargeStats.statusCounts).sort(
    ([statusA], [statusB]) => {
      const indexA = Object.keys(transactionStatuses).indexOf(statusA);
      const indexB = Object.keys(transactionStatuses).indexOf(statusB);

      if (indexA === -1) return 1; // statusA is not in transactionStatuses, move it to the end
      if (indexB === -1) return -1; // statusB is not in transactionStatuses, move it to the end

      return indexA - indexB; // sort according to the order in transactionStatuses
    }
  );

  return (
    <>
      <div className="my-2 d-flex align-items-center">
        <span>Progress:</span>
        <div className="progress flex-grow-1 ml-1">
          {progress > 5 ? (
            <div
              className={`progress-bar bg-info`}
              role="progressbar"
              style={{ width: `${progress}%` }}
            >
              {progress}%
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center flex-grow-1">
              {progress}%
            </div>
          )}
        </div>
      </div>
      <div className="ml-5 pl-3">
        {orderedStatusCounts.map(([status, count], idx) => (
          <span className={`mx-2 px-1 text-${transactionStatuses[status]?.level}`} key={idx}>
            <span className="mr-1">
              <i
                className={`mr-1 ${transactionStatuses[status]?.icon}`}
                data-toggle="tooltip"
                data-placement="bottom"
                title={transactionStatuses[status]?.description}
              />
              <strong>{count}</strong>
            </span>
            <span className={`text-${transactionStatuses[status]?.level}`}>
              {transactionStatuses[status]?.label || status}
            </span>
          </span>
        ))}
      </div>
      <div>
        <button
          className="btn btn-link p-0 pt-1"
          onClick={() => setLegendExpanded(!legendExpanded)}
        >
          See Transactions Legend
          <i className={`fa fa-angle-${legendExpanded ? 'up' : 'down'} pl-2`} />
        </button>
        <div className={`collapse ${legendExpanded ? 'show' : ''}`} id="legendCollapse">
          <div className="card card-body">
            {Object.entries(transactionStatuses).map(([status, details], idx) => (
              <div className="mr-2" key={idx}>
                <span className={`text-${details.level}`}>
                  <i className={`${details.icon}`} />
                  <u className="pl-2">{details.label}:</u>
                </span>
                <span className="pl-1">
                  <small>{details.description}</small>
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionProgressBar;
