import HorizontalFormTextField from '../../../components/horizontal-form/HorizontalFormTextField';
import HorizontalFormRow from '../../../components/horizontal-form/HorizontalFormRow';
import HorizontalFormLabel from '../../../components/horizontal-form/HorizontalFormLabel';
import { Form } from 'react-final-form';
import { eligibilityRulesOptions } from '../../../common/util/dropdownUtil';
import HorizontalFormDropdownField from '../../../components/horizontal-form/HorizontalFormDropdownField';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { handleErrorResponse, httpGet, httpPost } from '../../../../service/http';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import moment from 'moment';
import {
  createEligibilityRulesFormDetail,
  createEligibilityRulesRequestBodyDetail,
} from './createEligibilityRuleDetail';

import styles from './EligibilityRules.module.scss';
import WorkLocationOptions from './WorkLocationOptions';
import LegalEntityOptions from './LegalEntityOptions';
import EmploymentTypeOptions from './EmploymentTypeOptions';
import {
  ELIGIBILITY_RULE_OPTION_ACTIONS,
  useEligibilityRuleOption,
} from './reducers/useEligibilityRuleOption';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';
import GroupsOptions from './GroupsOptions';

function EligibilityRulesForm({ offer }) {
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);
  const formRef = useRef();
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [integrationScope, setIntegrationScope] = useState();
  const [savedEligibilityRules, setSavedEligibilityRules] = useState();
  const [loading, setLoading] = useState(true);
  const [eligibilityRuleOption, setEligibilityRuleOption] = useEligibilityRuleOption({
    isLegalEntitySectionOpen: false,
    isWorkLocationSectionOpen: false,
    isEmploymentTypeSectionOpen: false,
    isGroupsSectionOpen: false,
    legalEntities: new Set(),
    workLocations: new Set(),
    employmentTypes: new Set(),
    groups: new Set(),
  });

  const addSelectedOptionsToCheckList = useCallback(
    resp => {
      const stateOptions = {
        legalEntities: eligibilityRuleOption.legalEntities,
        workLocations: eligibilityRuleOption.workLocations,
        employmentTypes: eligibilityRuleOption.employmentTypes,
        groups: eligibilityRuleOption.groups,
      };
      Object.keys(stateOptions).forEach(key => {
        if (resp[key]) {
          resp[key].split(',').forEach(l => stateOptions[key].add(l));
        }
      });
      setEligibilityRuleOption({
        action: ELIGIBILITY_RULE_OPTION_ACTIONS.SET_ALL_ELIGIBILITY_OPTIONS,
        payload: stateOptions,
      });
    },
    [
      setEligibilityRuleOption,
      eligibilityRuleOption.legalEntities,
      eligibilityRuleOption.workLocations,
      eligibilityRuleOption.employmentTypes,
      eligibilityRuleOption.groups,
    ]
  );

  const handleCustomError = response => {
    if (response.ok) {
      return response.json();
    } else if (response.status !== 404) {
      handleErrorResponse(response);
    }
    return Promise.resolve();
  };

  useEffect(() => {
    const getIntegrationScope = async () => {
      if (offer === undefined) {
        return;
      }
      if (offer?.integrationScopeId == null) {
        setLoading(false);
        return;
      }
      if (integrationScope !== undefined) {
        return;
      }

      try {
        const integrationScopeDetail = await httpGet(
          `/v1/integration-scope/${offer.integrationScopeId}`
        );
        setIntegrationScope(integrationScopeDetail);

        await httpGet(
          `/v1/integration-scope/${offer.integrationScopeId}/eligibility-rule/latest`,
          null,
          handleCustomError
        )
          .then(eligibilityRulesDetail => {
            const dbEligibilityRules = createEligibilityRulesFormDetail(eligibilityRulesDetail);
            addSelectedOptionsToCheckList(dbEligibilityRules);
            setSavedEligibilityRules(dbEligibilityRules);
          })
          .catch(error => {
            console.log(error);
          });
      } finally {
        setLoading(false);
      }
    };
    executeWithSpinner(getIntegrationScope());
  }, [
    offer,
    executeWithSpinner,
    setEligibilityRuleOption,
    addSelectedOptionsToCheckList,
    integrationScope,
    savedEligibilityRules,
  ]);

  const createEligibilityRules = eligibilityRulesDetail => {
    const eligibilityRulesBody = createEligibilityRulesRequestBodyDetail(eligibilityRulesDetail);
    httpPost(
      `/v1/integration-scope/${offer.integrationScopeId}/eligibility-rule`,
      eligibilityRulesBody
    )
      .then(() => {
        window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      })
      .catch(error => {
        console.log(error);
      });
  };

  const changeEligibilityOptionsField = (field, value) => {
    formRef.current.mutators.setEligibilityOptionField(field, value);
  };

  return (
    <div>
      {!loading && (
        <Form
          onSubmit={createEligibilityRules}
          initialValuesEqual={() => true}
          initialValues={{
            ...offer,
            ...savedEligibilityRules,
            ...integrationScope,
          }}
          mutators={{
            setEligibilityOptionField: ([fieldName, fieldVal], state, { changeValue }) => {
              changeValue(state, fieldName, () => fieldVal);
            },
          }}
          render={({ submitError, handleSubmit, submitting, pristine, values, form }) => {
            formRef.current = form;
            return (
              <form onSubmit={handleSubmit}>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Integration Scope ID" />
                  <HorizontalFormTextField
                    controlId="integrationScopeId"
                    controlLabel="integrationScopeId"
                    placeholder="Integration UUID"
                    columnWidth={4}
                    disabled={true}
                    helpTextAfter={
                      <div>
                        Last sync:
                        {moment(integrationScope?.lastSyncedAt).format('DD.MM.YY h:mm:ss a')}
                      </div>
                    }
                    default
                  />
                </HorizontalFormRow>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Remote Integration ID" />
                  <HorizontalFormTextField
                    controlId="remoteIntegrationId"
                    controlLabel="remoteIntegrationId"
                    placeholder="Remote Integration ID"
                    columnWidth={4}
                    disabled={true}
                    default
                  />
                </HorizontalFormRow>
                <br />
                <h5>Eligibility rules</h5>
                <br />
                <span className="font-weight-bold">Tip:</span>
                <p>
                  If no rules are added for a category, all options will be included. Please be
                  careful to not accidentally exclude certain employee groups. You should
                  anticipate, that new groups will be added over time. Therefore we recommend that
                  you rather define who is excluded than to define who is included.
                </p>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Legal Entity" />
                  <HorizontalFormTextField
                    controlId="legalEntities"
                    controlLabel="legalEntities"
                    placeholder=""
                    columnWidth={4}
                    disabled={true}
                    helpTextAfter={
                      <span
                        className={styles.availableOptions}
                        onClick={() =>
                          setEligibilityRuleOption({
                            action: ELIGIBILITY_RULE_OPTION_ACTIONS.INVERT_LEGAL_ENTITY_DROPDOWN,
                          })
                        }
                      >
                        {isEditable && 'click here to add or remove available options'}
                      </span>
                    }
                  />
                  <HorizontalFormDropdownField
                    controlId="legalEntityCriteria"
                    controlLabel="legalEntityCriteria"
                    options={eligibilityRulesOptions()}
                    columnWidth={2}
                    disabled={!isEditable}
                  />
                </HorizontalFormRow>
                {eligibilityRuleOption.isLegalEntitySectionOpen && (
                  <LegalEntityOptions
                    eligibilityRuleOption={eligibilityRuleOption.legalEntities}
                    setEligibilityRuleOption={setEligibilityRuleOption}
                    changeEligibilityOptionsField={changeEligibilityOptionsField}
                    savedEligibilityRules={savedEligibilityRules?.legalEntities}
                    integrationScopeId={integrationScope?.id}
                  />
                )}
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Work location" />
                  <HorizontalFormTextField
                    controlId="workLocations"
                    controlLabel="workLocations"
                    placeholder=""
                    columnWidth={4}
                    disabled={true}
                    helpTextAfter={
                      <span
                        className={styles.availableOptions}
                        onClick={() =>
                          setEligibilityRuleOption({
                            action: ELIGIBILITY_RULE_OPTION_ACTIONS.INVERT_WORK_LOCATION_DROPDOWN,
                          })
                        }
                      >
                        {isEditable && 'click here to add or remove available options'}
                      </span>
                    }
                  />
                  <HorizontalFormDropdownField
                    controlId="workLocationCriteria"
                    controlLabel="workLocationCriteria"
                    options={eligibilityRulesOptions()}
                    columnWidth={2}
                    disabled={!isEditable}
                  />
                </HorizontalFormRow>
                {eligibilityRuleOption.isWorkLocationSectionOpen && (
                  <WorkLocationOptions
                    eligibilityRuleOption={eligibilityRuleOption.workLocations}
                    setEligibilityRuleOption={setEligibilityRuleOption}
                    changeEligibilityOptionsField={changeEligibilityOptionsField}
                    savedEligibilityRules={savedEligibilityRules?.workLocations}
                    integrationScopeId={integrationScope?.id}
                  />
                )}
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Employment Types" />
                  <HorizontalFormTextField
                    controlId="employmentTypes"
                    controlLabel="employmentTypes"
                    placeholder=""
                    columnWidth={4}
                    disabled={true}
                    helpTextAfter={
                      <span
                        className={styles.availableOptions}
                        onClick={() =>
                          setEligibilityRuleOption({
                            action: ELIGIBILITY_RULE_OPTION_ACTIONS.INVERT_EMPLOYMENT_TYPE_DROPDOWN,
                          })
                        }
                      >
                        {isEditable && 'click here to add or remove available options'}
                      </span>
                    }
                  />
                  <HorizontalFormDropdownField
                    controlId="employmentTypeCriteria"
                    controlLabel="employmentTypeCriteria"
                    options={eligibilityRulesOptions()}
                    columnWidth={2}
                    disabled={!isEditable}
                  />
                </HorizontalFormRow>
                {eligibilityRuleOption.isEmploymentTypeSectionOpen && (
                  <EmploymentTypeOptions
                    eligibilityRuleOption={eligibilityRuleOption.employmentTypes}
                    setEligibilityRuleOption={setEligibilityRuleOption}
                    changeEligibilityOptionsField={changeEligibilityOptionsField}
                    savedEligibilityRules={savedEligibilityRules?.employmentTypes}
                    integrationScopeId={integrationScope?.id}
                  />
                )}
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Groups" />
                  <HorizontalFormTextField
                    controlId="groups"
                    controlLabel="groups"
                    placeholder=""
                    columnWidth={4}
                    disabled={true}
                    helpTextAfter={
                      <span
                        className={styles.availableOptions}
                        onClick={() =>
                          setEligibilityRuleOption({
                            action: ELIGIBILITY_RULE_OPTION_ACTIONS.INVERT_GROUPS_DROPDOWN,
                          })
                        }
                      >
                        {isEditable && 'click here to add or remove available options'}
                      </span>
                    }
                  />
                  <HorizontalFormDropdownField
                    controlId="groupsCriteria"
                    controlLabel="groupsCriteria"
                    options={eligibilityRulesOptions()}
                    columnWidth={2}
                    disabled={!isEditable}
                  />
                </HorizontalFormRow>
                {eligibilityRuleOption.isGroupsSectionOpen && (
                  <GroupsOptions
                    eligibilityRuleOption={eligibilityRuleOption.groups}
                    setEligibilityRuleOption={setEligibilityRuleOption}
                    changeEligibilityOptionsField={changeEligibilityOptionsField}
                    savedEligibilityRules={savedEligibilityRules?.groups}
                    integrationScopeId={integrationScope?.id}
                  />
                )}
                <button
                  className="btn btn-block btn-secondary col-sm-3"
                  type="submit"
                  disabled={pristine}
                >
                  Save Eligibility Rules
                </button>
                <br />
              </form>
            );
          }}
        />
      )}
    </div>
  );
}

export default EligibilityRulesForm;
