import { formatMoneyAmount } from '../../../../utils/format/currencyFormatter';
import ChargeStatsCard from './ChargeStatsCard';
import TransactionProgressBar from './TransactionProgressBar';

type Props = {
  chargeStats: Object,
};

const TransactionStatsArea = ({ chargeStats }: Props) => {
  return (
    <>
      {chargeStats && (
        <div className="my-3">
          <h5>Transaction volumes charged in {chargeStats.period}: </h5>
          <div className="my-1">
            Total number of transactions:
            <strong className="ml-2">{chargeStats.total.count}</strong>
          </div>
          <div className="my-1">
            Total transaction volume:
            <strong className="ml-2">{formatMoneyAmount(chargeStats.total.volume)} </strong>
          </div>

          <TransactionProgressBar chargeStats={chargeStats} />
          <div className="d-flex">
            {Object.entries(chargeStats.countries).map(([country, { count, volume }]) => (
              <ChargeStatsCard key={country} country={country} count={count} volume={volume} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default TransactionStatsArea;
