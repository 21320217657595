import * as React from 'react';
import OfferContext from '../context/OfferContext';
import SpinnerContext from '../../../common/spinner/SpinnerContext';
import { useCallback, useEffect } from 'react';
import { httpGet, httpPatch } from '../../../../service/http';
import { SFCompany } from '../offer-details/types';
import { downloadBlob } from '../../../../utils/file/fileUtil';
import HorizontalFormDropdownField from '../../../components/horizontal-form/HorizontalFormDropdownField';
import { allowListIdentifierOptions } from '../../../common/util/dropdownUtil';
import { Form } from 'react-final-form';
import HorizontalFormRow from '../../../components/horizontal-form/HorizontalFormRow';
import HorizontalFormLabel from '../../../components/horizontal-form/HorizontalFormLabel';
import HorizontalFormTextField from '../../../components/horizontal-form/HorizontalFormTextField';

const EditAllowlist = () => {
  const { offer } = React.useContext(OfferContext);
  const { executeWithSpinner, isSpinnerShown } = React.useContext(SpinnerContext);
  const [company, setCompany] = React.useState();
  const [allowListCount, setAllowListCount] = React.useState(0);
  const [integrationScope, setIntegrationScope] = React.useState();

  const getCompanyInformation = useCallback(() => {
    if (!offer?.sfAccountCanonicalId) {
      return;
    }
    return httpGet(`/v1/salesforce/company?sfAccountCanonicalId=${offer?.sfAccountCanonicalId}`)
      .then(response => {
        const company = SFCompany.parse(response).find(
          c => c.canonicalId === offer?.sfAccountCanonicalId
        );
        if (company) {
          setCompany(company);
        }
      })
      .catch(error => {
        console.error('Error fetching the company details', error);
        setCompany({ canonicalId: offer?.sfAccountCanonicalId });
      });
  }, [offer?.sfAccountCanonicalId]);

  const getIntegrationScope = useCallback(async () => {
    try {
      if (!offer?.sfAccountCanonicalId) {
        return;
      }
      const integrationScopes = await httpGet(
        `/v1/integration-scope?externalId=${offer.sfAccountCanonicalId}`
      );
      if (!integrationScopes?.length) {
        return;
      }
      setIntegrationScope(integrationScopes[0]);
      setAllowListCount(integrationScopes[0]?.activeEmployeesCount);
      return integrationScopes[0];
    } catch (error) {
      console.error('Error fetching the latest integration scope', error);
    }
  }, [offer?.sfAccountCanonicalId]);

  useEffect(() => {
    const fetchCompanyAndScope = async () => {
      getCompanyInformation();
      getIntegrationScope();
    };
    executeWithSpinner(fetchCompanyAndScope());
  }, [offer?.sfAccountCanonicalId, executeWithSpinner, getCompanyInformation, getIntegrationScope]);

  const downloadEmployees = async () => {
    try {
      if (!offer?.sfAccountCanonicalId) {
        return;
      }
      const response = await fetch(
        `/v1/integration-scope/allowlist?externalId=${offer.sfAccountCanonicalId}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/octet-stream',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to download file');
      }

      const blob = await response.blob();
      downloadBlob(blob, 'allowList.csv');
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const onSubmit = async values => {
    if (values.allowListIdentifier !== integrationScope?.allowListIdentifier) {
      const patchBody = {
        allowListIdentifierType: values.allowListIdentifier,
      };
      const updatedIntegrationScope = await httpPatch(
        `/v1/integration-scope/${integrationScope?.id}`,
        patchBody
      );
      setIntegrationScope(updatedIntegrationScope);
    }
  };

  if (isSpinnerShown) {
    return <div />;
  }

  return (
    <Form
      initialValues={{
        sfAccountCountryCode: offer?.sfAccountCountryCode || '',
        sfAccountCanonicalId: offer?.sfAccountCanonicalId || '',
        sfAccountCompanyName: company?.name || '',
        allowListIdentifier: integrationScope?.allowListIdentifierType,
      }}
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div>
              <h4 className="mt-3 mb-3">Company Information</h4>
              <HorizontalFormRow>
                <HorizontalFormLabel controlLabel="Salesforce account name" />
                <HorizontalFormTextField
                  controlId="sfAccountCompanyName"
                  controlLabel="sfAccountCompanyName"
                  columnWidth={4}
                  disabled={true}
                />
              </HorizontalFormRow>
              <HorizontalFormRow>
                <HorizontalFormLabel controlLabel="Salesforce country code" />
                <HorizontalFormTextField
                  controlId="sfAccountCountryCode"
                  controlLabel="sfAccountCountryCode"
                  columnWidth={4}
                  disabled={true}
                />
              </HorizontalFormRow>
              <HorizontalFormRow>
                <HorizontalFormLabel controlLabel="Salesforce account canonical ID" />
                <HorizontalFormTextField
                  controlId="sfAccountCanonicalId"
                  controlLabel="sfAccountCanonicalId"
                  columnWidth={4}
                  disabled={true}
                />
              </HorizontalFormRow>

              <h4 className="mt-4 mb-3">Allowlist</h4>
              <h6>General Info</h6>
              <div className="row mt-3">
                <label className="col-md-3">CurrentAllowlist</label>
                <label className="col-md-3">{allowListCount} employees</label>
                <button
                  className="col-md-2 btn btn-primary"
                  type="button"
                  onClick={downloadEmployees}
                >
                  Download
                </button>
              </div>
              <div className="row mt-2">
                <label className="col-md-3">Identifier</label>
                <HorizontalFormDropdownField
                  controlId="allowListIdentifier"
                  controlLabel="allowListIdentifier"
                  options={allowListIdentifierOptions()}
                  columnWidth={4}
                  disabled={allowListCount > 0}
                />
              </div>
              <button
                className="btn btn-block btn-secondary col-sm-2 mt-2"
                type="submit"
                disabled={pristine}
              >
                Save
              </button>
            </div>
          </form>
        );
      }}
    />
  );
};

export default EditAllowlist;
