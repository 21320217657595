import React from 'react';
import Modal from '../../common/modal/Modal';
import { useNavigate } from 'react-router-dom';

type Props = {
  onCancel: Function,
};

const MoveMembershipOfferSuccessModal = ({ onCancel }: Props) => {
  const navigate = useNavigate();
  return (
    <Modal
      isOpen={true}
      onRequestClose={onCancel}
      modalTitle="Move to new offer request is processing"
    >
      <p className="text-black-50">
        Memberships are being moved to the new offer in the background. You will receive an email
        once the process is complete so please check your inbox for the status.
      </p>

      <div className="form-group form-row">
        <div className="col-sm-9" />
        <button
          className="btn btn-secondary col-sm-3"
          type="button"
          onClick={() => navigate('/companies/search')}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};
export default MoveMembershipOfferSuccessModal;
