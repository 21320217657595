// @flow
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { SpinnerProvider } from 'app/ui/common/spinner/SpinnerContext';
import Spinner from 'app/ui/common/spinner/Spinner';
import { AuthenticationProvider } from 'app/ui/common/authentication/AuthenticationContext';
import { OverlayProvider } from 'app/ui/common/modal/OverlayContext';
import Overlay from 'app/ui/common/modal/Overlay';
import DefaultErrorBoundary from './ui/error/DefaultErrorBoundary';
import appRouter from './ui/layout/AppRoutes';
import { EmojiRainProvider } from './ui/components/emoji-rain/EmojiRainContext';

const App = () => (
  <DefaultErrorBoundary>
    <AuthenticationProvider>
      <SpinnerProvider>
        <EmojiRainProvider>
          <OverlayProvider>
            <RouterProvider router={appRouter} />
            <Overlay />
          </OverlayProvider>
        </EmojiRainProvider>
        <Spinner />
      </SpinnerProvider>
    </AuthenticationProvider>
  </DefaultErrorBoundary>
);

export default App;
