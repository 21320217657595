// @flow
import * as React from 'react';
import { useCallback, useState } from 'react';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import { initialState, reducer } from 'app/ui/payment/list-payments/reducer';
import {
  getChargeStatsSuccess,
  getChargeStatsFailure,
  getFailedChargesSuccess,
  getFailedChargesFailure,
  loadingFailedCharges,
} from 'app/ui/payment/list-payments/actions';
import { DATE_VALIDATION_FAILURE_MESSAGE } from 'app/ui/payment/list-payments/paymentsAlertMessages';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import moment from 'moment';
import { httpGet, httpPost } from 'app/service/http';
import FailedChargesTable from './components/FailedChargesTable';
import NewPaymentLinkButton from '../../user-manager/user/NewPaymentLinkButton';
import { notificationService } from '../../../service/notification';
import styles from './ListPaymentsPage.module.scss';
import TransactionStatsArea from './components/TransactionStatsArea';

const ListPaymentsPage = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [period, setPeriod] = useState('');
  const [paymentProvider, setPaymentProvider] = useState('ADYEN');
  const [chargesStatus, setChargesStatus] = useState('');
  const { executeWithSpinner } = React.useContext(SpinnerContext);

  const getCharges = useCallback(
    (period, chargeStatus, provider) => {
      setChargesStatus(chargeStatus);
      dispatch(loadingFailedCharges());
      httpGet(
        `/v1/payment/charge?period=${period}&status=${chargeStatus}&provider=${provider}&pageSize=1000`
      )
        .then(charges => {
          dispatch(getFailedChargesSuccess(charges.content));
        })
        .catch(error => {
          dispatch(getFailedChargesFailure(error));
        });
    },
    [setChargesStatus]
  );

  const getPayments = useCallback(
    (period, status, provider) => {
      if (period && !moment(period, 'YYYY-MM', true).isValid()) {
        dispatch(getChargeStatsFailure(DATE_VALIDATION_FAILURE_MESSAGE));
        return;
      }

      executeWithSpinner(
        httpGet(`/v1/reporting/charges/stats?period=${period}&provider=${provider}`)
          .then(chargeStats => {
            dispatch(
              getChargeStatsSuccess({
                period,
                ...chargeStats,
              })
            );
          })
          .catch(error => {
            dispatch(getChargeStatsFailure(error));
          }),

        getCharges(period, status, provider)
      );
    },
    [executeWithSpinner, getCharges]
  );

  const onProviderChange = useCallback(
    selectedProvider => {
      setPaymentProvider(selectedProvider);

      if (!period) return;

      if (selectedProvider !== paymentProvider) {
        const formattedPeriod = moment(period, 'MM-YYYY').format('YYYY-MM');
        getPayments(formattedPeriod, 'ERROR', selectedProvider);
      }
    },
    [getPayments, period, paymentProvider]
  );

  const submit = useCallback(
    event => {
      event.preventDefault();
      const formattedPeriod = moment(period, 'MM-YYYY').format('YYYY-MM');
      getPayments(formattedPeriod, 'ERROR', paymentProvider);
    },
    [period, getPayments, paymentProvider]
  );

  const retryChargeActions = {
    ERROR: ({ charge }) => (
      <button
        className="btn btn-primary"
        onClick={() => {
          if (charge) {
            httpPost(`/v1/payment/charge/${charge.chargeId}/trigger`).then(() => {
              getPayments(charge.chargePeriod.month, 'ERROR', paymentProvider);
            });
          }
        }}
        disabled={paymentProvider === 'STRIPE'}
      >
        Retry
      </button>
    ),
    CHARGED_BACK: ({ charge }) => (
      <NewPaymentLinkButton
        chargeRequest={charge}
        onComplete={() => {
          getPayments(charge.chargePeriod.month, 'CHARGED_BACK', paymentProvider);
          notificationService.push({
            message: `Payment link for charge ${charge.chargeId} has been created`,
            variant: 'info',
          });
        }}
      />
    ),
  };

  const failedChargeOptions = [
    {
      id: 'errorCharges',
      label: 'Errors',
      status: 'ERROR',
    },
    {
      id: 'failedCharges',
      label: 'Failed',
      status: 'FAILED,FAILED_RETRIES_EXHAUSTED',
    },
    {
      id: 'chargeBackCharges',
      label: 'Charged back',
      status: 'CHARGED_BACK',
    },
  ];

  return (
    <>
      <form onSubmit={submit}>
        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <div className="input-group">
                <input
                  id="date"
                  type="text"
                  className="form-control"
                  value={period}
                  placeholder="Period MM-YYYY"
                  onChange={event => setPeriod(event.target.value)}
                />
                <div className="input-group-append">
                  <select
                    id="provider"
                    className={`form-control ${styles.provider}`}
                    onChange={event => onProviderChange(event.target.value)}
                  >
                    <option value={'ADYEN'}>{'Adyen'}</option>
                    <option value={'STRIPE'}>{'Stripe'}</option>
                  </select>
                </div>
                <div className="input-group-append">
                  <button className="btn btn-secondary" type="submit" disabled={!period}>
                    Show B2C Transaction Sums
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      {state.chargeStats && <TransactionStatsArea chargeStats={state.chargeStats} />}

      <div className="mb-3">
        <h5 className="d-inline">Failed payments: </h5>
        {period && (
          <div className="btn-group btn-group-toggle d-inline" data-toggle="buttons">
            {failedChargeOptions.map((option, idx) => (
              <label
                key={idx}
                className={`btn btn-secondary ${chargesStatus === option.status ? 'active' : ''}`}
              >
                <input
                  type="radio"
                  name="options"
                  id={option.id}
                  autoComplete="off"
                  onClick={() =>
                    getCharges(
                      moment(period, 'MM-YYYY').format('YYYY-MM'),
                      option.status,
                      paymentProvider
                    )
                  }
                />{' '}
                {option.label}
              </label>
            ))}
          </div>
        )}
      </div>
      {state.charges && (
        <FailedChargesTable
          charges={state.charges}
          loading={state.loadingFailedCharges}
          action={retryChargeActions[chargesStatus]}
        />
      )}

      {state.errorMessage && (
        <div className="alert alert-danger mt-4">
          {state.errorMessage.message ? state.errorMessage.message : state.errorMessage}
        </div>
      )}
      {state.emptyMembershipsMessage && (
        <div className="alert alert-dark mt-4">{state.emptyMembershipsMessage}</div>
      )}
    </>
  );
};

export default withPageTemplate(ListPaymentsPage, { headerTitle: 'B2C Payment Overview' });
