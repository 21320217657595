import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { httpGet } from 'app/service/http/index';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import CancelAllMembershipsForm from './CancelAllMembershipsForm';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { dateToISODateTimeBeginningOfDayCET } from '../../user-manager/user/memberships/membershipUtils';

const LIMIT = 1000;
const CancelAllMembershipsManager = () => {
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [memberships, setMemberships] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [activeMembershipsCount, setActiveMembershipsCount] = useState(0);
  const [futureMembershipsCount, setFutureMembershipsCount] = useState(0);

  const [searchParams] = useSearchParams();
  const sfAccountCanonicalId = searchParams.get('sfAccountCanonicalId');

  const [nextPageToken, setNextPageToken] = useState(null);

  const getMembershipPage = async (
    sfAccountCanonicalId,
    nextPageToken = null,
    membershipMinEndDateFilter = null
  ) => {
    const membershipsWithStatus = [];

    try {
      const pageTokenParam = nextPageToken ? `&pageToken=${nextPageToken}` : '';
      const membershipMinEndDateFilterParam = membershipMinEndDateFilter
        ? `&minEndTimestamp=${membershipMinEndDateFilter}`
        : '';
      const membershipsUrl = `/v1/membership?sfAccountCanonicalId=${sfAccountCanonicalId}&limit=${LIMIT}${pageTokenParam}${membershipMinEndDateFilterParam}`;
      const membershipsPage = await httpGet(membershipsUrl);
      membershipsPage.items.forEach(membership => {
        membershipsWithStatus.push({ membershipWrapper: membership });
      });

      return { memberships: membershipsWithStatus, nextPageToken: membershipsPage.nextPageToken };
    } catch (error) {}
  };

  const displayNextMembershipPage = useCallback(
    async (sfAccountCanonicalId, memberships, nextPageToken, membershipMinEndDateFilter) => {
      const nextPage = await getMembershipPage(
        sfAccountCanonicalId,
        nextPageToken,
        membershipMinEndDateFilter
      );

      const newMembershipList = memberships.concat(nextPage.memberships);
      setMemberships(newMembershipList);
      setNextPageToken(nextPage.nextPageToken);
    },
    [setNextPageToken, setMemberships]
  );

  const getCompanyInformation = sfAccountCanonicalId => {
    httpGet(`/v1/salesforce/company?sfAccountCanonicalId=${sfAccountCanonicalId}&minified=true`)
      .then(
        companyInformation => {
          setCompanyName(companyInformation.Name);
          setCompanyAddress(
            `${companyInformation.BillingAddress.street} ${companyInformation.BillingAddress.postalCode} ${companyInformation.BillingAddress.city}`
          );
        }
        // we rely on the generic httpGet implementation in service to handle the rejection here
      )
      .catch(() => {});

    httpGet(
      `/v1/membership-count?sfAccountCanonicalId=${sfAccountCanonicalId}&effectiveTimestamp=${encodeURIComponent(
        dateToISODateTimeBeginningOfDayCET(moment(new Date()).format('DD-MM-YYYY'))
      )}`
    ).then(membershipCount => {
      setActiveMembershipsCount(membershipCount[0].totalMembershipCount);
    });

    httpGet(
      `/v1/membership-count?sfAccountCanonicalId=${sfAccountCanonicalId}&minStartTimestamp=${encodeURIComponent(
        moment.tz('UTC').add(1, 'DAY').format('YYYY-MM-DD')
      )}`
    ).then(membershipCount => {
      setFutureMembershipsCount(membershipCount[0].totalMembershipCount);
    });
  };

  const updateMembership = (idToUpdate, fieldName, newValue) => {
    setMemberships(memberships =>
      memberships.map(membership =>
        idToUpdate === membership.membershipWrapper.membership.uuid
          ? { ...membership, [fieldName]: newValue }
          : membership
      )
    );
  };

  useEffect(() => {
    if (sfAccountCanonicalId) {
      executeWithSpinner(
        displayNextMembershipPage(
          sfAccountCanonicalId,
          [],
          null,
          moment.tz('UTC').format('YYYY-MM-DD')
        )
      );
      getCompanyInformation(sfAccountCanonicalId);
    }
  }, [sfAccountCanonicalId, executeWithSpinner, displayNextMembershipPage]);

  return (
    <>
      {sfAccountCanonicalId && (
        <>
          <CancelAllMembershipsForm
            memberships={memberships}
            sfAccountCanonicalID={sfAccountCanonicalId}
            updateMembership={updateMembership}
            companyName={companyName}
            companyAddress={companyAddress}
            getMembershipPage={getMembershipPage}
            activeMembershipsCount={activeMembershipsCount}
            futureMembershipsCount={futureMembershipsCount}
          />
          <button
            className="btn btn-secondary"
            type="button"
            onClick={() =>
              executeWithSpinner(
                displayNextMembershipPage(
                  sfAccountCanonicalId,
                  memberships,
                  nextPageToken,
                  moment.tz('UTC').format('YYYY-MM-DD')
                )
              )
            }
            disabled={!nextPageToken}
          >
            Load more
          </button>
        </>
      )}
    </>
  );
};

export default withPageTemplate(CancelAllMembershipsManager, {
  headerTitle: 'Cancel/Move All Memberships',
});
