// @flow
import React, { useState } from 'react';
import moment from 'moment';
import {
  formatISODateToDate,
  formatISOZonedDateTimeToLocalDate,
} from 'app/utils/format/dateTimeFormatter';
import type {
  ChronologyType,
  CorporateEmployeeSummary,
  MembershipOfferFrontendModel,
  MembershipType,
} from 'app/ui/types';
import type { User } from 'app/ui/user-manager/types';
import {
  employeeUnlinkingFailure,
  membershipRevokeFailure,
  membershipSaveSuccess,
  startChangingMembershipOffer,
  startEditingMembership,
} from 'app/ui/user-manager/user/memberships/actions';
import UserMembershipsContext from 'app/ui/user-manager/user/memberships/context/UserMembershipsContext';
import { createActivationLetterPdf } from 'app/utils/pdf/activationLetterCreatorPdf';
import { dateTimeToDate } from 'app/utils/date/dateUtil';
import { membershipCancellationDropdownOptions } from 'app/ui/user-manager/user/memberships/membershipUtils';
import Linkify from 'react-linkify';
import SpinnerContext from '../../../common/spinner/SpinnerContext';
import { httpPost } from '../../../../service/http';
import { fetchCheckins } from './validator';
import RevokeMembershipModal from './RevokeMembershipModal';
import UnlinkCorporateEmployeeModal from './UnlinkCorporateEmployeeModal';
import { getCountryFlag } from '../../../../utils/country/flagGenerator';

type Props = {
  membership: MembershipType,
  membershipOfferDto: MembershipOfferFrontendModel,
  user: User,
  relatedSignupPageNames: string,
  showOfferChangeMessage: boolean,
  chronology: ChronologyType,
  referrerMembership: MembershipType,
  referralMembership: MembershipType,
  isEditable: boolean,
  corporateEmployee: CorporateEmployeeSummary,
  showUnlinkHrisButton: boolean,
};

const Membership = ({
  membership: {
    uuid,
    userId,
    membershipOfferId,
    membershipStartTimestamp,
    membershipEndTimestamp,
    membershipEndChangeTimestamp,
    creationTimestamp,
    employeeIdentifier,
    membershipCancellationReason,
    referralLink,
    shareLinkReferrerUserId,
    timeZoneId,
  },
  membershipOfferDto: {
    priceAmount,
    priceCurrency,
    created,
    b2cPayment,
    type,
    sfAccountCountryCode,
  },
  user: { activationCode, firstName, lastName, gender },
  referrerMembership,
  referralMembership,
  relatedSignupPageNames,
  chronology,
  isEditable,
  corporateEmployees,
  showUnlinkHrisButton,
}: Props) => {
  const { dispatch } = React.useContext(UserMembershipsContext);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [revokeWarning, setRevokeWarning] = useState(null);
  const [unlinkDetails, setUnlinkDetails] = useState(false);

  const createAndPrintActivationLetterPdf = () => {
    const member = {
      activationCode: activationCode,
      firstName: firstName,
      lastName: lastName,
      gender: gender,
      membershipStartDate: dateTimeToDate(membershipStartTimestamp, timeZoneId),
    };

    // The relatedSignupPageNames prop could contain several company names separated by comma.
    const companyNames = relatedSignupPageNames && relatedSignupPageNames.split(',');
    const companyName = companyNames && companyNames.length > 0 ? companyNames[0] : '';

    const pdf = createActivationLetterPdf(member, companyName);
    pdf.save(
      `${moment().format('YYYYMMDD_HHMM')}_${relatedSignupPageNames.replace(
        / /g,
        '-'
      )}_${membershipOfferId}.pdf`
    );
  };

  const cancellationReasonOptions = membershipCancellationDropdownOptions();
  const getCancellationReasonLabel = value => {
    const found = cancellationReasonOptions.find(o => o.value === value);
    return found ? found.label : value;
  };

  const getRelatedUserId = () => {
    return referralMembership || referrerMembership
      ? referrerMembership
        ? referrerMembership.userId
        : referralMembership.userId
      : undefined;
  };

  const revokeMembership = async cancellationReason => {
    try {
      const endDate = membershipStartTimestamp
        ? moment(membershipStartTimestamp).format('YYYY-MM-DD')
        : null;
      const requestBody = {
        endDate: endDate,
        cancellationReason: cancellationReason,
      };

      const membershipWrapper = await httpPost(`/admin/v1/membership/${uuid}/cancel`, requestBody);
      dispatch(
        membershipSaveSuccess({
          ...membershipWrapper,
          referrerMembership: referrerMembership,
          referralMembership: referralMembership,
        })
      );
    } catch (error) {
      dispatch(membershipRevokeFailure(error && error.message));
    }
  };
  const reloadPage = () => window.location.reload();
  const unLinkHrisRecord = async unlinkHrisRecordDto => {
    try {
      const request = {
        egymAccountId: unlinkHrisRecordDto.egymAccountId,
      };
      await httpPost(
        `/admin/v1/employee/${unlinkHrisRecordDto.corporateEmployeeId}/unlink`,
        request
      )
        .then(() => reloadPage())
        .catch(error => {
          dispatch(employeeUnlinkingFailure(error && error.message));
        });
    } catch (error) {
      dispatch(employeeUnlinkingFailure(error && error.message));
    }
  };

  const onRevoke = cancellationReason => {
    return executeWithSpinner(
      fetchCheckins(userId, membershipStartTimestamp, membershipEndTimestamp).then(checkins => {
        const hasCheckins = checkins && checkins.size;
        if (!hasCheckins) {
          return revokeMembership(cancellationReason);
        } else {
          setRevokeWarning({ cancellationReason });
        }
      })
    );
  };

  const confirmUnlinkHrisRecord = corporateEmployeeId => {
    if (!corporateEmployeeId || !userId) return;
    setUnlinkDetails({
      egymAccountId: userId,
      corporateEmployeeId: corporateEmployeeId,
    });
  };

  const checkFutureMembership = () => {
    const isFutureMembership = chronology === 'FUTURE';
    const isMembershipEndTimestampValid =
      !membershipEndTimestamp ||
      moment(moment(membershipStartTimestamp).format('MM-DD-YYYY')).isBefore(
        moment(moment(membershipEndTimestamp).format('MM-DD-YYYY'))
      );
    return isFutureMembership && isMembershipEndTimestampValid;
  };

  const checkActiveMembership = () => {
    const isActiveMembership = chronology === 'ACTIVE';
    const isWithinFourDaysOfStart = moment().diff(moment(membershipStartTimestamp), 'days') <= 4;
    const isCurrentDateBeforeMembershipEnd =
      !membershipEndTimestamp || moment().isBefore(moment(membershipEndTimestamp));
    return isActiveMembership && isWithinFourDaysOfStart && isCurrentDateBeforeMembershipEnd;
  };

  const checkActiveAndFutureMembership = () => {
    return checkFutureMembership() || checkActiveMembership();
  };

  const buttons = [
    {
      name: 'Edit',
      className: 'btn-secondary',
      onClick: () => dispatch(startEditingMembership(uuid)),
      condition: true,
    },
    {
      name: 'Download activation letter',
      className: 'btn-secondary',
      onClick: createAndPrintActivationLetterPdf,
      condition: chronology !== 'PAST',
    },
    {
      name: 'Reject - Data Not Matching',
      className: 'btn-primary',
      onClick: () => onRevoke('PLUS1_REFUSED_DATA_NOT_MATCHING'),
      condition: type === 'PLUS_ONE' && checkFutureMembership(),
    },
    {
      name: 'Reject',
      className: 'btn-primary',
      onClick: () => onRevoke('REJECT_MEMBERSHIP'),
      condition: checkActiveAndFutureMembership(),
    },
  ];

  return (
    <div className="card mb-3">
      <div className="card-body">
        {/* //////////     Offer Info     ////////// */}
        <div className="row mb-3">
          <h6 className="col-6 col-md-8 col-lg-9 col-xl-10">
            Offer Info {getCountryFlag(sfAccountCountryCode)}
          </h6>
          {(chronology === 'ACTIVE' || chronology === 'FUTURE') && (
            <button
              className="col btn btn-secondary mr-2"
              onClick={() =>
                dispatch(startChangingMembershipOffer(uuid, b2cPayment, sfAccountCountryCode))
              }
              disabled={!isEditable}
            >
              Change offer
            </button>
          )}
        </div>
        <div className="row">
          <label className="col-12 col-lg-2">
            Offer ID:{' '}
            <a
              href={`${window.location.origin}/companies/offer-manager/${membershipOfferId}/details`}
              target="blank"
            >
              {membershipOfferId}
            </a>
          </label>
          <label className="col-12 col-lg-2">
            Price: {priceAmount} {priceCurrency} ({b2cPayment ? 'B2C Direct Pay' : 'Payroll'})
          </label>
          <label className="col-12 col-lg-2">
            Creation Date: {formatISOZonedDateTimeToLocalDate(created, timeZoneId)}
          </label>
          <label className="col-12 col-lg-2">Offer Type: {type}</label>
          <label className="col-12 col-lg-4">Related Signup Pages: {relatedSignupPageNames}</label>
        </div>

        {/* //////////     Membership Info     ////////// */}
        <div className="row my-3">
          <h6 className="col">Membership Info</h6>
        </div>
        <div className="row">
          <label className="col-12 col-lg-4 col-xl-2">
            Start: {formatISOZonedDateTimeToLocalDate(membershipStartTimestamp, timeZoneId)}
          </label>
          <label className="col-12 col-lg-4 col-xl-2">
            End: {formatISOZonedDateTimeToLocalDate(membershipEndTimestamp, timeZoneId)}
          </label>
          <label className="col-12 col-lg-4 col-xl-2">
            Cancellation Reason: {getCancellationReasonLabel(membershipCancellationReason)}
          </label>
          <label className="col-12 col-lg-4 col-xl-2">
            Cancellation Time:{' '}
            {formatISOZonedDateTimeToLocalDate(membershipEndChangeTimestamp, timeZoneId)}
          </label>
          <label className="col-12 col-lg-4 col-xl-2">
            Membership Creation: {formatISOZonedDateTimeToLocalDate(creationTimestamp, timeZoneId)}
          </label>
          <label className="col-12 col-lg-4 col-xl-2">
            Personal Identifier: {employeeIdentifier}
          </label>
        </div>
        <div className="row">
          <label className="col-12 col-lg-4 col-xl-6">Membership UUID: {uuid}</label>
        </div>
        {(referralMembership || referralLink || referrerMembership) &&
          sfAccountCountryCode !== 'US' && (
            <div>
              <div className="row my-3">
                <h6 className="col">Referral Programs</h6>
              </div>
              {(referralMembership || referrerMembership) && (
                <div className="row mb-3">
                  <div className="col-12">
                    {referrerMembership ? 'Plus 1 referrer ID: ' : 'Plus 1 partner ID: '}
                    <a
                      href={`${
                        window.location.origin
                      }/users/user/${getRelatedUserId()}/memberships`}
                      target="blank"
                    >
                      {getRelatedUserId()}
                    </a>
                  </div>
                </div>
              )}
              {referralLink && (
                <div className="row mb-3">
                  <div className="col-12">
                    <Linkify properties={{ target: '_blank' }}>
                      Plus 1 invitation Link: {referralLink}
                    </Linkify>
                  </div>
                </div>
              )}
              {shareLinkReferrerUserId && (
                <div className="row">
                  <div className="col-12">
                    {'Invite a colleague referrer ID: '}
                    <a
                      href={`${window.location.origin}/users/user/${shareLinkReferrerUserId}/memberships`}
                      target="blank"
                    >
                      {shareLinkReferrerUserId}
                    </a>
                  </div>
                </div>
              )}
            </div>
          )}
        {corporateEmployees?.length > 0 && (
          <div>
            <div className="row my-3">
              <h6 className="col">HRIS Connection</h6>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Corporate Employee ID</th>
                  <th>Employment Type</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Full Name</th>
                  <th>Birthday</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {corporateEmployees.map(employee => (
                  <tr key={employee.id}>
                    <td>{employee.id}</td>
                    <td>{employee.employmentType}</td>
                    <td>{moment(employee.startDate).format('DD-MM-YYYY')}</td>
                    <td>
                      {employee.terminationDate
                        ? moment(employee.terminationDate).format('DD-MM-YYYY')
                        : 'Not Present'}
                    </td>
                    <td>
                      {employee.firstName} {employee.lastName}
                    </td>
                    <td>{formatISODateToDate(employee.birthday)}</td>
                    <td>
                      {showUnlinkHrisButton && (
                        <button
                          className="btn btn-link"
                          onClick={() => {
                            confirmUnlinkHrisRecord(employee.id);
                          }}
                        >
                          Unlink HRIS
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="card-footer">
        {buttons
          .filter(btn => btn.condition)
          .map((btn, idx) => (
            <button
              key={idx}
              className={`ml-2 btn ${btn.className}`}
              onClick={btn.onClick}
              disabled={!isEditable}
            >
              {btn.name}
            </button>
          ))}
      </div>

      {revokeWarning && (
        <RevokeMembershipModal
          onConfirm={() => revokeMembership(revokeWarning.cancellationReason)}
          onCancel={() => setRevokeWarning(null)}
        />
      )}

      {unlinkDetails && (
        <UnlinkCorporateEmployeeModal
          onConfirm={() => unLinkHrisRecord(unlinkDetails)}
          onCancel={() => setUnlinkDetails(null)}
        />
      )}
    </div>
  );
};

export default Membership;
