import React, { useEffect, useState } from 'react';
import EligibilityOptions from './EligibilityOptions';
import { ELIGIBILITY_RULE_OPTION_ACTIONS } from './reducers/useEligibilityRuleOption';
import SpinnerContext from '../../../common/spinner/SpinnerContext';
import { httpGet } from '../../../../service/http';
import { notificationService } from '../../../../service/notification';

const headers = [
  {
    key: 'groupId',
    label: 'Group Id',
  },
  {
    key: 'groupName',
    label: 'Group Name',
  },
];

const GroupsOptions = ({
  eligibilityRuleOption,
  setEligibilityRuleOption,
  changeEligibilityOptionsField,
  savedEligibilityRules,
  integrationScopeId,
}) => {
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [body, setBody] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGroupsOptions = async () => {
      try {
        setLoading(true);
        await httpGet(`/v1/integration-scope/${integrationScopeId}/groups`).then(data => {
          if (!data?.length) {
            notificationService.push({
              message: 'No Groups found for employees',
              variant: 'warning',
            });
            return;
          }
          const transformedGroups = data?.map(({ id, name }) => ({
            groupId: id,
            groupName: name,
          }));

          setBody(transformedGroups);
        });
      } finally {
        setLoading(false);
      }
    };
    executeWithSpinner(fetchGroupsOptions());
  }, [executeWithSpinner, savedEligibilityRules, integrationScopeId]);

  return (
    !loading &&
    body.length > 0 && (
      <EligibilityOptions
        keyField={'groupId'}
        headers={headers}
        body={body}
        selectedOptions={eligibilityRuleOption}
        setSelectedOptions={setEligibilityRuleOption}
        optionType={ELIGIBILITY_RULE_OPTION_ACTIONS.SET_GROUPS}
        changeEligibilityOptionsField={changeEligibilityOptionsField}
      />
    )
  );
};

export default GroupsOptions;
