import React, { useState, useContext } from 'react';
import ConfirmationModal from '../../common/modal/ConfirmationModal';
import type { MembershipOfferFrontendModel } from '../../types';
import { formatISOZonedDateTimeToCETDate } from 'app/utils/format/dateTimeFormatter';
import DropdownFormField from '../../components/DropdownFormField';
import { Form } from 'react-final-form';
import { httpPost } from '../../../service/http';
import SpinnerContext from '../../common/spinner/SpinnerContext';
import MoveMembershipOfferSuccessModal from './MoveMembershipOfferSuccessModal';
import MoveMembershipOfferFailureModal from './MoveMembershipOfferFailureModal';

type Props = {
  onCancel: Function,
  sfAccountCanonicalId: string,
  newOffer: MembershipOfferFrontendModel,
  showB2CWarning: boolean,
};

const MoveMembershipConfirmationModal = ({
  onCancel,
  sfAccountCanonicalId,
  newOffer,
  showB2CWarning,
}: Props) => {
  const { executeWithSpinner } = useContext(SpinnerContext);
  const [showConfirmation, setShowConfirmation] = useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const currentDate = new Date();
  const nextMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);

  const options = [
    { value: '', label: '' },
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];

  let submit;
  const submitEvent = event => {
    submit(event);
  };

  const submitMoveAllMemberships = values => {
    const moveMemberships = async () => {
      const requestBody = {
        newOfferId: newOffer.id,
        movePersonalId: values.moveMembership.personalId,
      };
      const moveOfferURL = `/admin/v1/company/${sfAccountCanonicalId}/membership/move-offer`;
      await httpPost(moveOfferURL, requestBody)
        .then(moveMembershipOfferDto => {
          setShowSuccessMessage(true);
        })
        .catch(error => {
          setErrorMessage(error?.message);
          setShowFailureMessage(true);
        })
        .finally(() => {
          setShowConfirmation(false);
        });
    };
    executeWithSpinner(moveMemberships());
  };

  const isWellpassOffer = !window._env_.REACT_APP_GYMLIB_COUNTRY_CODES.split(',').includes(
    newOffer.sfAccountCountryCode
  );

  return (
    <>
      <Form
        onSubmit={submitMoveAllMemberships}
        render={({ handleSubmit, pristine }) => {
          submit = handleSubmit;
          return (
            <ConfirmationModal
              isOpen={showConfirmation}
              modalTitle="Move all memberships to a new offer?"
              yesButtonText="Move all memberships to new offer"
              noButtonText="Close"
              onConfirm={submitEvent}
              onCancel={onCancel}
              yesButtonDisabled={pristine}
            >
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <p className="text-black-50">
                    <span>Details for </span>
                    <span className={'text-primary'}>offer ID {newOffer.id}</span>
                  </p>
                  <p className="text-black-50">
                    <strong>Type: </strong> {newOffer.type} <br />
                    <strong>Price: </strong> {newOffer.price?.amount} {newOffer.price?.currency}
                    <br />
                    <strong>B2C Payment: </strong> {newOffer.b2cPayment ? 'YES' : 'NO'} <br />
                    <strong>Personal ID: </strong> {newOffer.employeeInternalIdentifierLabel}
                  </p>
                  {showB2CWarning && (
                    <div className="container">
                      <div className="row alert alert-info mt-3">
                        We detected that some offers you move, had Payroll as B2C payment method.
                        Please contact the affected members and ask them to enter payment data{' '}
                        <a target="blank" href={`https://app.egym-wellpass.com/payment-details`}>
                          here
                        </a>
                      </div>
                    </div>
                  )}
                  {isWellpassOffer ? (
                    <>
                      <p className="text-primary">Membership start date for new offer</p>
                      <p className="text-black-50">
                        <strong>{formatISOZonedDateTimeToCETDate(nextMonthDate)}</strong>
                      </p>
                    </>
                  ) : (
                    <p className="text-primary">
                      The start date for new memberships is at the end of the current period.
                    </p>
                  )}
                  <div className="form-group">
                    <DropdownFormField
                      controlId="moveMembership.personalId"
                      controlLabel="Copy personal identifier values to the new memberships?"
                      options={options}
                      formatOnBlur
                    />
                  </div>
                </div>
              </form>
            </ConfirmationModal>
          );
        }}
      />

      {showSuccessMessage && <MoveMembershipOfferSuccessModal onCancel={onCancel} />}
      {showFailureMessage && (
        <MoveMembershipOfferFailureModal onCancel={onCancel} errorMessage={errorMessage} />
      )}
    </>
  );
};

export default MoveMembershipConfirmationModal;
